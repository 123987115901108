// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import Footer from 'components/SqPrivacyFooter';
import { selectBoolFlag } from 'store/featureSlice';
import { AnonymousBoolFlag } from 'routes/profile/models/Flags';
import { useHistory, useLocation } from 'react-router-dom';
import Es2Tracker from 'services/tracking/tracker';
import { AppState } from 'store';
import AddIdentifier from './AddIdentifier';
import AddIdentifierTOTP from './AddIdentifierTotp';
import CollectName from './CollectName';
import IdentifierClaimedError from './IdentifierClaimedError';
import IsThisYourProfile from './IsThisYourProfile';
import PostAuthRedirect from './PostAuthRedirect';
import SignIn from './SignIn';
import SignInTOTP from './SignInTotp';
import { IdentifierType } from 'routes/profile/models/Identifier';
import {
  isValidPrefilledPhoneVariantData,
  parseGenericParameters,
  parseOnboardingVariantFromQueryParams,
  parseVariantDataFromHistoryState,
  parseVariantDataFromQueryParams,
} from './util/parameters';
import { NativeSignInStep, OnboardingVariant } from './util/constants';
import {
  Name,
  SignInIdentifier,
  SignInIdentifierType,
  AddIdentifierNextAction,
  SignInVariantData,
  PrefilledPhoneData,
  OnboardingVariantValue,
  LoyaltyData,
} from './types';
import { MarketToast } from '@market/react';
import { useTranslation } from 'react-i18next';
import { Identifier as RpcIdentifier } from 'rpc/model/squareup/buyerportal/profile/common';
import {
  signInIdentifierClaimedAddIdentifierActionEvent,
  signInIdentifierClaimedSignInActionEvent,
  signInSkipProfileRepair,
} from 'services/tracking/events/signIn';
import LockedPhoneSignIn from './LockedPhoneSignIn';
import GlobalLoader from 'routes/profile/common/loading/GlobalLoader';
import LoyaltyVariantSignIn from './LoyaltyVariantSignIn';
import { VerificationCredential } from 'rpc/model/squareup/buyerportal/common/data';
import { LoyaltyTermsOfService } from 'rpc/model/squareup/card/balance/model/loyalty-terms-of-service';
import { useCreateLoyaltyAccount } from 'utils/custom-react-hooks/loyalty/useCreateLoyaltyAccount';
import {
  createLoyaltySubRouteUrl,
  LoyaltySubRoute,
} from 'routes/merchant-scoped-portal/integrations/loyalty/routeUtils';

// Enables mocking
export type NativeSignInProps = {
  step?: NativeSignInStep;
  firstIdentifier?: SignInIdentifier;
  secondIdentifier?: SignInIdentifier;
  personToken?: string;
  name?: Name;
};

// eslint-disable-next-line complexity
const NativeSignIn: React.FC<NativeSignInProps> = (props) => {
  const { t } = useTranslation();

  const areAnonymousFlagsLoaded = useSelector(
    (state: AppState) => state.feature.areAnonymousFlagsLoaded
  );
  const useNativeSignIn = useSelector((state: AppState) =>
    selectBoolFlag(state, AnonymousBoolFlag.useNativeSignIn)
  );

  const [isUnexpectedError, setIsUnexpectedError] = useState<boolean>(false);

  const [firstIdentifier, setFirstIdentifier] = useState<
    SignInIdentifier | undefined
  >(props.firstIdentifier || undefined);

  // For unverified emails that have associated verified phones
  const [isVerifyingAssociatedPhone, setIsVerifyingAssociatedPhone] =
    useState<boolean>(false);
  const [secondIdentifier, setSecondIdentifier] = useState<
    SignInIdentifier | undefined
  >(props.secondIdentifier || undefined);
  const [name, setName] = useState<Name | undefined>(props.name || undefined);
  const [personToken, setPersonToken] = useState<string | undefined>(
    props.personToken || undefined
  );
  const [claimedIdentifierType, setClaimedIdentifierType] = useState<
    SignInIdentifierType | undefined
  >(undefined);

  const rawQueryParams = new URLSearchParams(useLocation().search);
  const genericOnboardingParams = parseGenericParameters(rawQueryParams);
  const locationState = useLocation().state;

  const [onboardingVariant] = useState<OnboardingVariantValue | null>(
    parseOnboardingVariantFromQueryParams(rawQueryParams)
  );
  const [onboardingVariantData] = useState<
    SignInVariantData[keyof SignInVariantData] | null
  >(() =>
    onboardingVariant
      ? parseVariantDataFromQueryParams(onboardingVariant, rawQueryParams) ??
        parseVariantDataFromHistoryState(onboardingVariant, locationState)
      : null
  );

  const history = useHistory();
  const [newlyAcceptedTos, setNewlyAcceptedTos] =
    useState<LoyaltyTermsOfService | null>(null);
  const verificationCredential =
    firstIdentifier?.type === IdentifierType.Phone
      ? VerificationCredential.create({
          phoneNumber: firstIdentifier.value,
          phoneNumberId: firstIdentifier.id,
        })
      : null;
  const createLoyaltyAccount = useCreateLoyaltyAccount({
    newlyAcceptedTos,
    onboardingVariantData,
    verificationCredential,
  });

  const shouldShowLoyaltyVariant =
    onboardingVariant === OnboardingVariant.Loyalty &&
    Boolean(onboardingVariantData);

  // If we successfully parsed the variant-specific data, now start making decisions based on the variant.
  // First of which is flow routing.
  const [step, setStep] = useState<NativeSignInStep>(() => {
    let step;
    // First, handle overrides from the query params
    if (
      onboardingVariant === OnboardingVariant.PrefilledPhone &&
      Boolean(onboardingVariantData)
    ) {
      step = NativeSignInStep.LockedPhoneSignIn;
    }

    if (shouldShowLoyaltyVariant) {
      step = NativeSignInStep.LoyaltyVariantSignIn;
    }

    // Then, handle overrides from the props
    if (props.step) {
      step = props.step;
    }
    // Finally, default to the SignIn step
    return step || NativeSignInStep.SignIn;
  });

  // set a unique identifier for the user if they don't have one
  useEffect(() => {
    if (!Cookies.get('_savt')) {
      Cookies.set('_savt', uuidv4());
    }
  }, []);

  if (!areAnonymousFlagsLoaded) {
    return <GlobalLoader />;
  }

  // TODO: Remove this. Feature flag is fully rolled out.
  if (!genericOnboardingParams.forceNative && !useNativeSignIn) {
    window.location.assign(
      `/login?app=buyer-portal&totp_flow=email&return_to=${genericOnboardingParams.returnTo}`
    );
    return <></>;
  }

  // The exception should have been populated with a meaningful error message by this point
  const onUnexpectedError = (
    isCatastrophic?: boolean,
    exception?: string | Error
  ) => {
    if (isCatastrophic) {
      Sentry.captureException(exception || new Error('onUnexpectedError'), {
        user: { id: Cookies.get('_savt') || 'anonymous' },
      });
    }

    setIsUnexpectedError(true);

    if (isCatastrophic) {
      setStep(NativeSignInStep.SignIn);
    }
  };

  const changeStep = (step: NativeSignInStep) => {
    setIsUnexpectedError(false);
    setStep(step);
  };

  let component;
  const finalStep = genericOnboardingParams.stepOverride || step;
  switch (finalStep) {
    default:
    case NativeSignInStep.LockedPhoneSignIn: {
      // Given the step override query param could have been modified without supplying the appropriate 'data' param
      // we defensively extract the obfuscated value.
      // use the type guard just defined in parameters.ts to check the signInVariantData is a phoneprefill data type
      // if it is, then we can safely access the obfuscatedValue
      if (!isValidPrefilledPhoneVariantData(onboardingVariantData)) {
        onUnexpectedError(
          true,
          new Error(
            'PrefilledPhoneSignIn precondition failed: signInVariantData is not a valid PrefilledPhoneData object'
          )
        );
        break;
      }
      const prefilledPhoneData = onboardingVariantData as PrefilledPhoneData;
      const { obfuscatedValue, fingerprint } = prefilledPhoneData;
      component = (
        <LockedPhoneSignIn
          obfuscatedPhoneIdentifier={{
            id: fingerprint,
            type: IdentifierType.Phone,
            value: obfuscatedValue,
          }}
          onForward={(fingerprintedPhoneId: string) => {
            setFirstIdentifier({
              id: fingerprintedPhoneId,
              type: IdentifierType.Phone,
              value: obfuscatedValue,
            });
            changeStep(NativeSignInStep.SignInTOTP);
          }}
          onSignInToDifferentAccount={() => {
            changeStep(NativeSignInStep.SignIn);
          }}
          onUnexpectedError={onUnexpectedError}
        />
      );
      break;
    }
    case NativeSignInStep.LoyaltyVariantSignIn: {
      const loyaltyData = onboardingVariantData as LoyaltyData;
      component = (
        <LoyaltyVariantSignIn
          identifier={firstIdentifier}
          loyaltyData={loyaltyData}
          onForward={(
            phoneNumber: string,
            agreedToTos?: LoyaltyTermsOfService
          ) => {
            setFirstIdentifier({
              type: IdentifierType.Phone,
              value: phoneNumber,
            });
            if (agreedToTos) {
              setNewlyAcceptedTos(agreedToTos);
            }
            changeStep(NativeSignInStep.SignInTOTP);
          }}
          onUnexpectedError={onUnexpectedError}
        />
      );
      break;
    }
    case NativeSignInStep.SignIn: {
      component = (
        <SignIn
          identifier={firstIdentifier}
          onForward={(value: string, type: SignInIdentifierType) => {
            setFirstIdentifier({
              type,
              value,
            });

            changeStep(NativeSignInStep.SignInTOTP);
          }}
          onAuthMethodChange={() => {
            // The second identifier will have been prepopulated if the user restarted the flow after running into the
            // "identifier claimed" error. We need to reset this when the auth method is changed so we don't
            // request an email/phone twice in a row.
            setSecondIdentifier(undefined);
          }}
          onUnexpectedError={onUnexpectedError}
        />
      );
      break;
    }
    case NativeSignInStep.SignInTOTP: {
      if (!firstIdentifier) {
        onUnexpectedError(
          true,
          new Error(
            'SignInTOTP precondition failed: First identifier not populated'
          )
        );
        break;
      }

      component = (
        <SignInTOTP
          loyaltyData={
            shouldShowLoyaltyVariant
              ? (onboardingVariantData as LoyaltyData)
              : undefined
          }
          identifier={firstIdentifier}
          onBack={() => {
            if (shouldShowLoyaltyVariant) {
              changeStep(NativeSignInStep.LoyaltyVariantSignIn);
            } else {
              changeStep(NativeSignInStep.SignIn);
            }
          }}
          onForward={async (
            pToken: string,
            missingIdentifier: RpcIdentifier | undefined
          ) => {
            if (pToken) {
              setPersonToken(pToken);
              // Set person token on tracker to differentiate between new/returning/users in AddIdentifier(TOTP) steps
              Es2Tracker.setBuyerToken(pToken);
            }

            if (shouldShowLoyaltyVariant) {
              const loyaltyAccount = await createLoyaltyAccount();
              if (loyaltyAccount) {
                history.push(
                  createLoyaltySubRouteUrl({
                    loyaltyAccountLookupToken:
                      loyaltyAccount.loyaltyAccountLookupToken,
                    merchantId: (onboardingVariantData as LoyaltyData)
                      .merchantId,
                    subRoute: LoyaltySubRoute.Rewards,
                  })
                );
              } else {
                onUnexpectedError();
              }
              return;
            }

            const hasMissingIdentifier =
              missingIdentifier?.type &&
              [
                RpcIdentifier.Type.TYPE_EMAIL,
                RpcIdentifier.Type.TYPE_PHONE,
              ].includes(missingIdentifier.type);
            // Business logic here:
            // The second identifier may have been pre-populated. This would happen if we caught
            // the "adding a claimed identifier to new account" case, and we restarted the flow,
            // prefilling the identifiers in reverse order so the user could *sign in* and
            // add the unclaimed identifier to their existing account.
            // If this is the case, we only want to overwrite that prepopulated value
            // if the missing identifier is the phone associated with an unverified email.
            //
            // We also want to write to 2nd identifier if there's no prefilled 2nd identifier to begin with.
            if (hasMissingIdentifier) {
              const isMissingIdentifierTheAssociatedPhone =
                missingIdentifier.type === RpcIdentifier.Type.TYPE_PHONE &&
                Boolean(missingIdentifier.id);
              if (isMissingIdentifierTheAssociatedPhone || !secondIdentifier) {
                setSecondIdentifier({
                  id: missingIdentifier.id,
                  type:
                    missingIdentifier.type === RpcIdentifier.Type.TYPE_EMAIL
                      ? IdentifierType.Email
                      : IdentifierType.Phone,
                  value: missingIdentifier.displayValue,
                });
              }
            }

            if (pToken && hasMissingIdentifier) {
              changeStep(NativeSignInStep.AddIdentifier);
            } else if (pToken && !hasMissingIdentifier) {
              changeStep(NativeSignInStep.PostAuthRedirect);
            } else if (!pToken && hasMissingIdentifier) {
              changeStep(NativeSignInStep.IsThisYourProfile);
            } else {
              // !pToken && !hasMissingIdentifier
              changeStep(NativeSignInStep.CollectName);
            }
          }}
          onUnexpectedError={onUnexpectedError}
        />
      );

      break;
    }
    case NativeSignInStep.CollectName: {
      component = (
        <CollectName
          firstName={name?.firstName || ''}
          lastName={name?.lastName || ''}
          onBack={() => {
            changeStep(NativeSignInStep.SignIn);
          }}
          onForward={(firstName: string, lastName: string) => {
            setName({
              firstName,
              lastName,
            });

            changeStep(NativeSignInStep.AddIdentifier);
          }}
        />
      );
      break;
    }
    case NativeSignInStep.AddIdentifier: {
      if (!firstIdentifier) {
        onUnexpectedError(
          true,
          new Error(
            'AddIdentifier precondition failed: First identifier not populated'
          )
        );
        break;
      }

      // Doesn't require the check for `isVerifyingAssociatedPhone' because *either* IsThisYourProfile
      // OR AddIdentifier is rendered
      if (!personToken && !name) {
        // For a new buyer, a name should've been provided in the flow.
        onUnexpectedError(
          true,
          new Error(
            'AddIdentifier precondition failed: New user did not have name populated'
          )
        );
        break;
      }

      const neededIdentifierType: SignInIdentifierType =
        secondIdentifier?.type ||
        (firstIdentifier.type === IdentifierType.Email
          ? IdentifierType.Phone
          : IdentifierType.Email);

      const postCreateOnForward = (
        input: string,
        wasEmailClaimed?: boolean
      ) => {
        setSecondIdentifier({
          type: neededIdentifierType,
          value: input,
        });

        if (wasEmailClaimed) {
          setClaimedIdentifierType(IdentifierType.Email);
          changeStep(NativeSignInStep.IdentifierClaimedError);
        } else {
          changeStep(NativeSignInStep.PostAuthRedirect);
        }
      };

      const postTotpTriggerOnForward = (input: string) => {
        setSecondIdentifier({
          type: neededIdentifierType,
          value: input,
        });
        changeStep(NativeSignInStep.AddIdentifierTOTP);
      };

      const addIdentifierNextAction =
        !personToken && neededIdentifierType === IdentifierType.Email
          ? AddIdentifierNextAction.CreateProfile
          : AddIdentifierNextAction.CollectTOTP;

      component = (
        <AddIdentifier
          nextAction={addIdentifierNextAction}
          identifier={{
            id: secondIdentifier?.id || undefined,
            type: neededIdentifierType,
            value: secondIdentifier?.value || '',
          }}
          personToken={personToken}
          newBuyerName={name}
          onBack={() => {
            setSecondIdentifier(undefined);
            if (personToken) {
              changeStep(NativeSignInStep.SignIn);
            } else {
              changeStep(NativeSignInStep.CollectName);
            }
          }}
          onForward={
            addIdentifierNextAction === AddIdentifierNextAction.CreateProfile
              ? postCreateOnForward
              : postTotpTriggerOnForward
          }
          onUnexpectedError={onUnexpectedError}
        />
      );
      break;
    }
    case NativeSignInStep.AddIdentifierTOTP: {
      if (!secondIdentifier) {
        onUnexpectedError(
          true,
          new Error(
            'AddIdentifierTOTP precondition failed: Second identifier not populated'
          )
        );
        break;
      }

      // For a new buyer, a name should've been provided in the flow.
      // The exception are 'new' buyers who entered an unverified email first and are now verifying their associated phone.
      // Unverified emails do not yield a person token from the backend
      if (!personToken && !isVerifyingAssociatedPhone && !name) {
        onUnexpectedError(
          true,
          new Error(
            'AddIdentifierTOTP precondition failed: New buyer missing name'
          )
        );
        break;
      }

      component = (
        <AddIdentifierTOTP
          identifier={secondIdentifier}
          isVerifyingAssociatedPhone={isVerifyingAssociatedPhone}
          newBuyerName={personToken ? undefined : name}
          onUnexpectedError={onUnexpectedError}
          onBack={() => {
            if (isVerifyingAssociatedPhone) {
              changeStep(NativeSignInStep.IsThisYourProfile);
            } else {
              changeStep(NativeSignInStep.AddIdentifier);
            }
          }}
          onForward={(
            claimedIdentifierType: SignInIdentifierType | undefined
          ) => {
            setClaimedIdentifierType(claimedIdentifierType);

            if (claimedIdentifierType) {
              changeStep(NativeSignInStep.IdentifierClaimedError);
            } else {
              changeStep(NativeSignInStep.PostAuthRedirect);
            }
          }}
        />
      );
      break;
    }
    case NativeSignInStep.PostAuthRedirect: {
      component = <PostAuthRedirect />;
      break;
    }
    case NativeSignInStep.IdentifierClaimedError: {
      if (!claimedIdentifierType) {
        onUnexpectedError(
          true,
          new Error(
            'IdentifierClaimedError precondition failed: Claimed identifier not populated'
          )
        );
        break;
      }

      const claimedIdentifierDetails: {
        identifier: SignInIdentifier | undefined;
        index: number | undefined;
      } = { identifier: undefined, index: undefined };

      if (claimedIdentifierType === firstIdentifier?.type) {
        claimedIdentifierDetails.identifier = firstIdentifier;
        claimedIdentifierDetails.index = 0;
      } else if (claimedIdentifierType === secondIdentifier?.type) {
        claimedIdentifierDetails.identifier = secondIdentifier;
        claimedIdentifierDetails.index = 1;
      }
      const { identifier: claimedIdentifier, index: claimedIdentifierIndex } =
        claimedIdentifierDetails;
      if (!claimedIdentifier) {
        onUnexpectedError(
          true,
          new Error(
            `IdentifierClaimedError precondition failed: Claimed identifier type did not match the type of either identifier entered in the flow.\nClaimedIdentifierDetails.type: ${JSON.stringify(
              claimedIdentifierType
            )}\nIdentifier 1 type: ${
              firstIdentifier?.type
            } Identifier 2 type: ${secondIdentifier?.type}`
          )
        );
        break;
      }

      component = (
        <IdentifierClaimedError
          claimedIdentifier={claimedIdentifier}
          personToken={personToken}
          onForward={() => {
            setClaimedIdentifierType(undefined);
            if (personToken) {
              Es2Tracker.track(signInIdentifierClaimedSignInActionEvent());
              // Person token indicates an account exists for the first identifier.
              // Why we're here: The user attemped to add a claimed identifier from another account - ask them to "try again" with a new identifier
              setSecondIdentifier(undefined);
              changeStep(NativeSignInStep.AddIdentifier);
            } else {
              // No account for first identifier (unclaimed identifier or an unverified email)
              Es2Tracker.track(
                signInIdentifierClaimedAddIdentifierActionEvent()
              );
              // Why we're here: User tried adding a claimed identifier to a new account. Ask user to sign in with claimed.
              // Prefill the first identifier with the claimed identifier they just failed to add
              setFirstIdentifier(claimedIdentifier);
              // Prefill the 2nd identifier with whatever wasn't claimed (likely the 1st identifier)
              // We'll overwrite this if need be (e.g. if they login with an unverified email, we'll
              // still display the verified phone)
              setSecondIdentifier(
                claimedIdentifierIndex === 1
                  ? firstIdentifier
                  : secondIdentifier
              );
              setName(undefined);

              // Route back to the beginning
              changeStep(NativeSignInStep.SignIn);
            }
          }}
          onForwardSecondary={(claimedIdentifier: SignInIdentifier) => {
            Es2Tracker.track(signInSkipProfileRepair(claimedIdentifier.type));
            changeStep(NativeSignInStep.PostAuthRedirect);
          }}
        />
      );
      break;
    }
    case NativeSignInStep.IsThisYourProfile: {
      if (!secondIdentifier?.id) {
        onUnexpectedError(
          true,
          new Error(
            'IsThisYourProfile precondition failed: secondIdentifier not populated'
          )
        );
        break;
      }

      component = (
        <IsThisYourProfile
          identifierId={secondIdentifier.id}
          identifierValue={secondIdentifier.value}
          onBack={() => {
            setSecondIdentifier(undefined);
            changeStep(NativeSignInStep.SignIn);
          }}
          onForward={() => {
            setIsVerifyingAssociatedPhone(true);
            changeStep(NativeSignInStep.AddIdentifierTOTP);
          }}
          onForwardDecline={() => {
            // Reset to false in case the user 'back'ed to this screen again
            changeStep(NativeSignInStep.CollectName);
            setIsVerifyingAssociatedPhone(false);
            setSecondIdentifier({
              id: '',
              type: IdentifierType.Phone,
              value: '',
            });
          }}
          onUnexpectedError={onUnexpectedError}
        />
      );
      break;
    }
  }

  return (
    <>
      <div className={'flex justify-center min-h-full grow'}>
        <div
          className={
            'flex flex-col justify-center py-[47px] px-[24px] w-[calc(100vw-48px)] sm:w-[550px]'
          }
        >
          {component}
          {isUnexpectedError && (
            <div className={'absolute bottom-[26px]'}>
              <MarketToast
                variant={'critical'}
                onMarketToastManuallyDismissed={() =>
                  setIsUnexpectedError(false)
                }
                onMarketToastAutoDismissed={() => setIsUnexpectedError(false)}
              >
                {t('common.somethingWentWrong.retryable.apologetic')}
              </MarketToast>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NativeSignIn;
