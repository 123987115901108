import {
  CollectedNativeSignInData,
  LoyaltyData,
  PrefilledPhoneData,
} from '../types';

export const DEBOUNCE_WAIT = 1000;
export const MAX_CODE_LENGTH = 6;
export const MAX_NAME_LENGTH = 100; // TODO(rcatlin): lookup

export enum GenericOnboardingQueryParam {
  ForceNative = 'force_native',
  ReturnTo = 'return_to',
  StepDebug = 'step_debug',
}

export const VARIANT_QUERY_PARAM_KEY = 'variant';

export const HEX_MEDIUM_GREY = '#f2f2f2';
export const HEX_LIGHT_GREY = '#e6e6e6';
export const HEX_DARK_GREY = '#6d6d6d';
export const INTERNATIONAL_PHONE_INPUT_COLORS = Object.freeze({
  DARK: '#6d6d6d',
  LIGHT: '#e6e6e6',
  MEDIUM: '#f2f2f2',
});

export enum NativeSignInStep {
  LockedPhoneSignIn = 'LockedPhoneSignIn',
  LoyaltyVariantSignIn = 'LoyaltyVariantSignIn',
  SignIn = 'SignIn',
  SignInTOTP = 'SignInTOTP',
  CollectName = 'CollectName',
  AddIdentifier = 'AddIdentifier',
  AddIdentifierTOTP = 'AddIdentifierTOTP',
  PostAuthRedirect = 'PostAuthRedirect',
  IsThisYourProfile = 'IsThisYourProfile',
  IdentifierClaimedError = 'IdentifierClaimedError',
}

export const INITIAL_DATA: CollectedNativeSignInData = {
  buyer: undefined,
  existingBuyer: false,
  firstIdentifier: undefined,
  name: undefined,
  secondIdentifier: undefined,
};

export const SEND_CODE_THROTTLE_DURATION = 2000;
export const THROTTLE_TIMEOUT = 10000;

export const TEXT_TO_INPUT_SPACING = 24;
export const INPUT_TO_BUTTON_SPACING = 24;

export const LOYALTY_DATA_KEYS: Array<keyof LoyaltyData> = [
  'merchantId',
  'programId',
  'isTosConsentOptional',
  'claimPointsToken',
  'buyer',
];

export const PREFILLED_PHONE_DATA_KEYS: Array<keyof PrefilledPhoneData> = [
  'obfuscatedValue',
  'fingerprint',
];

export { OnboardingVariant } from '../types';
