import React from 'react';
import GiftCardIcon from 'svgs/market-icons/GiftCard';
import { MarketAccessory, MarketContentCard, MarketRow } from '@market/react';
import { LoyaltyProgramRewardTier } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { LoyaltyProgramTerminologyFactory } from 'routes/merchant-scoped-portal/integrations/loyalty/testing/factories/LoyaltyProgram';
import { useTranslation } from 'react-i18next';

interface RewardTierProps {
  rewardTier: LoyaltyProgramRewardTier;
}

// TODO: retrieve terminology from loyalty slice
const TERMINOLOGY = LoyaltyProgramTerminologyFactory.build();

const RewardTier: React.FC<RewardTierProps> = ({ rewardTier }) => {
  const { t } = useTranslation();

  const pointsTerminology =
    rewardTier.points === 1 ? TERMINOLOGY.one : TERMINOLOGY.other;

  return (
    <MarketContentCard className="py-0 mb-4 not:last-child:mb-0">
      <MarketRow>
        <label slot="label">
          {t('loyalty.common.points', {
            count: rewardTier.points,
            pointsTerminology,
          })}
        </label>
        <p slot="subtext">{rewardTier.name}</p>
        <MarketAccessory
          className="bg-blue rounded"
          size="image"
          slot="leading-accessory"
        >
          <GiftCardIcon fill="white" />
        </MarketAccessory>
      </MarketRow>
    </MarketContentCard>
  );
};

export default RewardTier;
