import React, { HTMLAttributes } from 'react';
import { MarketList } from '@market/react';
import RewardTier from './RewardTier';
import { LoyaltyProgramRewardTier } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';

// eslint-disable-next-line no-undef
interface RewardTierProps extends HTMLAttributes<HTMLMarketListElement> {
  rewardTiers: readonly LoyaltyProgramRewardTier[];
}

const RewardTierList: React.FC<RewardTierProps> = ({
  rewardTiers,
  ...props
}) => {
  return (
    <MarketList {...props}>
      {rewardTiers.map((rewardTier) => (
        <RewardTier key={rewardTier.id} rewardTier={rewardTier}></RewardTier>
      ))}
    </MarketList>
  );
};

export default RewardTierList;
