import { buyerportalApi } from 'store/query';
import { callApi, getResponseError } from '../utils';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import {
  AccountInfo,
  GetTermsOfServiceRequest,
  GetTermsOfServiceResponse,
  LoyaltyPromotionDetail,
  ProgramInfo,
  RetrieveEmailCollectionIncentiveTermsAndStatusResponse,
  RetrieveLoyaltyAccountInfoRequest,
  RetrieveLoyaltyAccountInfoResponse,
  RetrieveLoyaltyProgramInfoRequest,
  RetrieveLoyaltyProgramInfoResponse,
  SearchLoyaltyPromotionsResponse,
  SearchLoyaltyPromotionsRequest,
  RetrieveLoyaltyPromotionDetailResponse,
  RetrieveLoyaltyPromotionDetailRequest,
  RetrieveLoyaltyAccountInfoForBuyerResponse,
  UpdateTermsOfServiceConsentRequest,
  UpdateTermsOfServiceConsentResponse,
  RetrieveClaimablePointsResponse,
  CreateLoyaltyAccountForBuyerResponse,
  CreateLoyaltyAccountForBuyerRequest,
} from 'rpc/model/squareup/buyerportal/merchantportal/loyalty/data';
import loyaltyClient from 'rpc/loyaltyClient';
import {
  collectEmailIncentive,
  removePaymentCard,
  setAccountInfo,
  setAvailableNewTos,
  setEmailCollectionIncentiveTermsAndStatus,
  setLinkedPaymentCards,
  setPassDownloadData,
  setProgramInfo,
  setPromotions,
} from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import { RetrieveLoyaltyAccountInfoResponseFactory } from 'routes/merchant-scoped-portal/integrations/loyalty/testing/factories/LoyaltyService';
import passesClient from 'rpc/passes-client';
import {
  CreatePersonalizedLoyaltyPassUrlRequest,
  CreatePersonalizedLoyaltyPassUrlResponse,
} from 'rpc/model/squareup/passes/service/url-service';
import client from 'rpc/client';
import {
  CardPreference,
  SearchCardPreferencesResponse,
  UpdateCardPreferenceResponse,
  UpdateCardPreferenceRequest,
} from 'rpc/model/squareup/buyerportal/preference/card';
import { Identifier } from 'rpc/model/squareup/buyerportal/profile/common';
import {
  CardInfo,
  VerificationCredential,
} from 'rpc/model/squareup/buyerportal/common/data';
import { LoyaltyPromotion } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-promotion';
import { EmailCollectionIncentiveTermsAndStatus } from 'rpc/model/squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status';
import { LoyaltyTermsOfService } from 'rpc/model/squareup/card/balance/model/loyalty-terms-of-service';
import { ClaimablePoints } from 'rpc/model/squareup/card/balance/loyalty_api/model/claimable-points';
import { LoyaltyAccount } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-account';

const MOCK_LOYALTY_ACCOUNT_LOOKUP_TOKEN = 'mock';

export const loyaltyApi = buyerportalApi.injectEndpoints({
  endpoints: (builder) => ({
    collectEmailIncentive: builder.mutation<
      null,
      {
        agreedToCopyToken: string;
        email: string;
        firstName?: string;
        lastName?: string;
        loyaltyAccountLookupToken: string;
      }
    >({
      async queryFn(
        {
          agreedToCopyToken,
          email: emailAddress,
          firstName: givenName,
          lastName: surname,
          loyaltyAccountLookupToken,
        },
        api
      ) {
        const response = await loyaltyClient.collectEmailForIncentive({
          agreedToCopyTokens: [agreedToCopyToken],
          emailAddress,
          givenName,
          loyaltyAccountLookupToken,
          surname,
        });

        if (response.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response);
        }
        api.dispatch(collectEmailIncentive());

        return { data: null };
      },
    }),
    createLoyaltyAccountForBuyer: builder.mutation<
      LoyaltyAccount | undefined,
      {
        claimablePointsToken?: string;
        idempotencyKey: string;
        loyaltyProgramId: string;
        merchantId: string;
        verificationCredential: VerificationCredential;
      }
    >({
      async queryFn({
        claimablePointsToken,
        idempotencyKey,
        loyaltyProgramId,
        merchantId,
        verificationCredential,
      }) {
        const response = await callApi<CreateLoyaltyAccountForBuyerResponse>(
          () =>
            loyaltyClient.createLoyaltyAccountForBuyer(
              CreateLoyaltyAccountForBuyerRequest.create({
                claimablePointsToken,
                idempotencyKey,
                loyaltyProgramId,
                merchantId,
                verificationCredential,
              })
            )
        );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        return { data: response.data.loyaltyAccount };
      },
    }),
    getTermsOfService: builder.query<
      LoyaltyTermsOfService | undefined,
      {
        loyaltyAccountLookupToken?: string;
        merchantId: string;
      }
    >({
      async queryFn({ merchantId, loyaltyAccountLookupToken }, api) {
        const response = await callApi<GetTermsOfServiceResponse>(() =>
          loyaltyClient.getTermsOfService(
            GetTermsOfServiceRequest.create({
              loyaltyAccountLookupToken,
              merchantId,
            })
          )
        );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        api.dispatch(setAvailableNewTos(response.data.availableNewTos));

        return {
          data: response.data.availableNewTos,
        };
      },
    }),
    retrieveAccountInfo: builder.query<
      RetrieveLoyaltyAccountInfoResponse.Info | undefined,
      string
    >({
      async queryFn(loyaltyAccountLookupToken: string, api) {
        let response;
        // TODO: Remove this mock once the endpoint is implemented (LOYALTY-8904)
        if (loyaltyAccountLookupToken === MOCK_LOYALTY_ACCOUNT_LOOKUP_TOKEN) {
          response = {
            data: RetrieveLoyaltyAccountInfoResponseFactory.build(),
          };
        } else {
          response = await callApi<RetrieveLoyaltyAccountInfoResponse>(() =>
            loyaltyClient.retrieveLoyaltyAccountInfo(
              RetrieveLoyaltyAccountInfoRequest.create({
                loyaltyAccountLookupToken,
              })
            )
          );
        }

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        const accountInfo = response.data?.info?.account;
        if (accountInfo) {
          api.dispatch(setAccountInfo(accountInfo));
        }

        return {
          data: response.data?.info,
        };
      },
    }),
    retrieveAccountInfoForBuyer: builder.query<readonly AccountInfo[], string>({
      async queryFn(merchantId: string) {
        const response =
          await callApi<RetrieveLoyaltyAccountInfoForBuyerResponse>(() =>
            loyaltyClient.retrieveLoyaltyAccountInfoForBuyer({ merchantId })
          );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        return {
          data: response.data?.accounts ?? [],
        };
      },
    }),
    retrieveClaimablePoints: builder.query<ClaimablePoints | undefined, string>(
      {
        async queryFn(claimablePointsToken) {
          const response = await callApi<RetrieveClaimablePointsResponse>(() =>
            loyaltyClient.retrieveClaimablePoints({ claimablePointsToken })
          );

          return {
            data: response.data?.claimablePoints,
          };
        },
      }
    ),
    retrieveEmailCollectionIncentive: builder.query<
      EmailCollectionIncentiveTermsAndStatus | undefined,
      string
    >({
      async queryFn(loyaltyAccountLookupToken: string, api) {
        const response =
          await callApi<RetrieveEmailCollectionIncentiveTermsAndStatusResponse>(
            () =>
              loyaltyClient.retrieveEmailCollectionIncentiveTermsAndStatus({
                loyaltyAccountLookupToken,
              })
          );

        const incentiveTermsAndStatus =
          response.data?.emailCollectionIncentiveInfo;
        api.dispatch(
          setEmailCollectionIncentiveTermsAndStatus(incentiveTermsAndStatus)
        );

        return {
          data: incentiveTermsAndStatus,
        };
      },
    }),
    retrievePersonalizedPassInfo: builder.query<
      | CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData
      | undefined,
      string
    >({
      async queryFn(loyaltyAccountToken: string, api) {
        const response =
          await callApi<CreatePersonalizedLoyaltyPassUrlResponse>(() =>
            passesClient.createPersonalizedLoyaltyPassUrl({
              loyaltyAccountToken,
              source:
                CreatePersonalizedLoyaltyPassUrlRequest.SOURCE.MERCHANT_PORTAL,
            })
          );

        const passDownloadData = response.data?.personalizedPassDownloadData;
        api.dispatch(setPassDownloadData(passDownloadData));

        return {
          data: passDownloadData,
        };
      },
    }),
    retrieveProgramInfo: builder.query<ProgramInfo | undefined, string>({
      async queryFn(merchantId: string, api) {
        const response = await callApi<RetrieveLoyaltyProgramInfoResponse>(() =>
          loyaltyClient.retrieveLoyaltyProgramInfo(
            RetrieveLoyaltyProgramInfoRequest.create({
              merchantId,
            })
          )
        );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        const programInfo = response.data?.programInfo;
        if (programInfo) {
          api.dispatch(setProgramInfo(programInfo));
        }

        return {
          data: programInfo,
        };
      },
    }),
    retrievePromotionDetail: builder.query<
      LoyaltyPromotionDetail | undefined,
      {
        merchantId: string;
        programId: string;
        promotionId: string;
      }
    >({
      async queryFn({ merchantId, programId, promotionId }) {
        const request = new RetrieveLoyaltyPromotionDetailRequest.Builder()
          .merchantId(merchantId)
          .loyaltyProgramId(programId)
          .loyaltyPromotionId(promotionId)
          .build();
        const response = await callApi<RetrieveLoyaltyPromotionDetailResponse>(
          () => loyaltyClient.retrieveLoyaltyPromotionDetail(request)
        );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        return {
          data: response.data.promotionDetail,
        };
      },
    }),
    searchLinkedPaymentMethods: builder.query<CardInfo[], Identifier>({
      async queryFn(loyaltyPhoneIdentifier: Identifier, api) {
        const response = await callApi<SearchCardPreferencesResponse>(() =>
          client.searchCardPreferences({
            identifier: loyaltyPhoneIdentifier,
            product: CardPreference.Product.PRODUCT_LOYALTY,
          })
        );

        const cardInfoAndPreferences =
          response.data?.cardInfoAndPreferences ?? [];
        const cards = cardInfoAndPreferences
          .map((cardInfoAndPreference) => cardInfoAndPreference.cardInfo)
          .filter(Boolean) as CardInfo[];

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        api.dispatch(setLinkedPaymentCards(cards));

        return { data: cards };
      },
    }),
    searchPromotions: builder.query<
      LoyaltyPromotion[],
      {
        merchantId: string;
        programId: string;
      }
    >({
      async queryFn({ merchantId, programId }, api) {
        // Pagination is not required. The backend is expected to only return active/upcoming
        // promotions and a seller can have at most 10 at any given time
        const request = new SearchLoyaltyPromotionsRequest.Builder()
          .loyaltyProgramId(programId)
          .merchantId(merchantId)
          .build();

        const response = await callApi<SearchLoyaltyPromotionsResponse>(() =>
          loyaltyClient.searchLoyaltyPromotions(request)
        );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        const promotions = response.data?.loyaltyPromotions
          ? [...response.data.loyaltyPromotions]
          : [];
        api.dispatch(setPromotions(promotions));

        return { data: promotions };
      },
    }),
    unlinkPaymentCard: builder.mutation<
      null,
      {
        cardId: string;
        loyaltyPhoneIdentifier: Identifier;
      }
    >({
      async queryFn({ cardId, loyaltyPhoneIdentifier }, api) {
        const request = new UpdateCardPreferenceRequest.Builder()
          .actionType(CardPreference.UpdateActionType.UPDATE_ACTION_UNLINK)
          .cardId(cardId)
          .cardPreference(
            new CardPreference.Builder()
              .identifier(loyaltyPhoneIdentifier)
              .product(CardPreference.Product.PRODUCT_LOYALTY)
              .type(CardPreference.Type.TYPE_IDENTIFIER)
              .build()
          )
          .build();
        const response = await callApi<UpdateCardPreferenceResponse>(() =>
          client.updateCardPreference(request)
        );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          throw new Error('Failed to unlink card');
        }

        api.dispatch(removePaymentCard(cardId));

        return { data: null };
      },
    }),
    updateTermsOfServiceConsent: builder.mutation<
      null,
      {
        newlyAcceptedTos: LoyaltyTermsOfService;
        loyaltyAccountLookupToken: string;
      }
    >({
      async queryFn({ newlyAcceptedTos, loyaltyAccountLookupToken }) {
        const response = await callApi<UpdateTermsOfServiceConsentResponse>(
          () =>
            loyaltyClient.updateTermsOfServiceConsent(
              UpdateTermsOfServiceConsentRequest.create({
                loyaltyAccountLookupToken,
                newlyAcceptedTos,
              })
            )
        );

        if (response.data?.status !== RequestStatus.STATUS_SUCCESS) {
          return getResponseError(response.data);
        }

        return { data: null };
      },
    }),
  }),
});

export const {
  useCollectEmailIncentiveMutation,
  useCreateLoyaltyAccountForBuyerMutation,
  useGetTermsOfServiceQuery,
  useRetrieveAccountInfoQuery,
  useRetrieveAccountInfoForBuyerQuery,
  useRetrieveClaimablePointsQuery,
  useRetrieveEmailCollectionIncentiveQuery,
  useRetrievePersonalizedPassInfoQuery,
  useRetrieveProgramInfoQuery,
  useRetrievePromotionDetailQuery,
  useSearchLinkedPaymentMethodsQuery,
  useSearchPromotionsQuery,
  useUnlinkPaymentCardMutation,
  useUpdateTermsOfServiceConsentMutation,
} = loyaltyApi;
