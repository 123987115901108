import React from 'react';
import { MarketRow } from '@market/react';
import { useTranslation } from 'react-i18next';
import { LoyaltyProgramFactory } from '../testing/factories/LoyaltyProgram';
import AddToAppleWalletButton from 'components/AddToAppleWalletButton';

const LOYALTY_PROGRAM = LoyaltyProgramFactory.build();
const MERCHANT_NAME = 'Le Petit Chien';

interface AddToAppleWalletSectionProps {
  loyaltyPassDownloadUrl: string;
}

const AddToAppleWalletSection: React.FC<AddToAppleWalletSectionProps> = ({
  loyaltyPassDownloadUrl,
}) => {
  const { t } = useTranslation();

  return (
    <section>
      <MarketRow>
        <label slot="label">{t('loyalty.addToAppleWalletSection.title')}</label>
        <p slot="subtext">
          {t('loyalty.addToAppleWalletSection.subtext', {
            merchantName: MERCHANT_NAME,
            pointsTerminology: LOYALTY_PROGRAM.terminology?.other,
          })}
        </p>
      </MarketRow>
      <AddToAppleWalletButton downloadUrl={loyaltyPassDownloadUrl} />
    </section>
  );
};

export default AddToAppleWalletSection;
