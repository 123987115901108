import React, { useContext } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';
import AccountPage from './AccountPage';
import EarningPage from './EarningPage';
import RewardsPage from './RewardsPage';
import { authenticatedLoyaltyRoutePattern } from '../routeUtils';

import PointExpirationPreview from '../components/PointsExpirationPreview';
import { ExpiringPointsDeadline } from '../models/ExpiringPointsDeadline';
import LoyaltyAccountStatus from '../components/LoyaltyAccountStatus';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  selectAccount,
  selectAvailableNewTos,
  setAvailableNewTos,
} from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import {
  useGetTermsOfServiceQuery,
  useRetrieveAccountInfoQuery,
  useRetrievePersonalizedPassInfoQuery,
  useUpdateTermsOfServiceConsentMutation,
} from 'store/query/api-extensions/loyalty';
import { useLoyaltyContext } from '../LoyaltyContext';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { ModalType, openModal } from 'store/modalSlice';
import { useTranslation } from 'react-i18next';
import { chooseConsentCopy } from 'routes/merchant-scoped-portal/integrations/loyalty/utils/consentCopy';
import * as Sentry from '@sentry/react';

// Custom hook that fetches the loyalty terms of service for the loyalty account
// and shows the terms of service dialog if there are new terms to accept
const useShowTermsOfServiceModal = (
  loyaltyAccountLookupToken: string,
  loyaltyAccountToken: string,
  merchantId: string
) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, error } = useGetTermsOfServiceQuery({
    loyaltyAccountLookupToken,
    merchantId,
  });

  if (error) {
    Sentry.captureException(error);
  }

  const availableNewTos = useSelector((state: AppState) =>
    selectAvailableNewTos(state.loyaltyMerchantPortal)
  );

  const [updateTermsOfService] = useUpdateTermsOfServiceConsentMutation();

  if (availableNewTos) {
    const handleAgreeToTermsOfServiceConsent = async () => {
      try {
        await updateTermsOfService({
          loyaltyAccountLookupToken,
          newlyAcceptedTos: availableNewTos,
        }).unwrap();
        dispatch(setAvailableNewTos(undefined));
      } catch {
        Sentry.captureException('Error updating terms of service consent', {
          extra: {
            availableNewTos,
            loyaltyAccountToken,
          },
        });
        // If the update fails for some reason, we will still dismiss the modal.
        // Since the consent hasn't been updated, the next visit to the page
        // will show the modal again.
        dispatch(setAvailableNewTos(undefined));
      }
    };

    const handleDeclineToTermsOfServiceConsent = () => {
      history.push(`/merchantportal/${merchantId}/loyalty`);
    };

    const tosCopy = chooseConsentCopy(i18n.language, availableNewTos.copies);

    if (tosCopy) {
      dispatch(
        openModal({
          props: {
            header: t('loyalty.termsOfService.header'),
            persistent: true,
            primaryButtonAction: handleAgreeToTermsOfServiceConsent,
            primaryButtonLabel: t('common.agree'),
            secondaryButtonAction: handleDeclineToTermsOfServiceConsent,
            secondaryButtonLabel: t('common.decline'),
            subtext: tosCopy.content,
          },
          type: ModalType.BasicDialog,
        })
      );
    } else {
      Sentry.captureException('Consent copy not found for available new TOS', {
        extra: {
          availableNewTos,
          loyaltyAccountToken,
        },
      });
    }
  }

  return {
    error,
    isLoading,
  };
};

const AuthenticatedLoyaltyResourcePage = () => {
  const loyaltyContext = useLoyaltyContext();
  const loyaltyAccountLookupToken =
    loyaltyContext.loyaltyAccountLookupToken ?? '';
  const mpContext = useContext(MerchantPortalContext);
  const merchantId =
    mpContext.merchantPortalBaseData?.merchantOverview.merchantId ?? '';

  const {
    isLoading: isLoadingAccountInfo,
    isUninitialized,
    isError,
  } = useRetrieveAccountInfoQuery(loyaltyAccountLookupToken, {
    skip: !loyaltyAccountLookupToken,
  });

  const account = useSelector((state: AppState) =>
    selectAccount(state.loyaltyMerchantPortal)
  );

  const loyaltyAccountToken = account?.loyaltyAccount?.id ?? '';
  const { isLoading: isLoadingPassInfo } = useRetrievePersonalizedPassInfoQuery(
    loyaltyAccountToken,
    {
      skip: !loyaltyAccountToken,
    }
  );

  const { isLoading: isLoadingTos } = useShowTermsOfServiceModal(
    loyaltyAccountLookupToken,
    loyaltyAccountToken,
    merchantId
  );

  if (
    isLoadingAccountInfo ||
    isLoadingPassInfo ||
    isLoadingTos ||
    isUninitialized
  ) {
    return <ModuleLoading embedded={true} />;
  }

  if (isError || !account?.loyaltyAccount) {
    return <ModuleLoadFailed embedded={true} />;
  }

  const expiringPointDeadlines =
    account.loyaltyAccount.expiringPointDeadlines?.map((deadline) =>
      ExpiringPointsDeadline.fromProto(deadline)
    );

  return (
    <>
      <div className="mt-[-140px]">
        <LoyaltyAccountStatus
          loyaltyAccount={account.loyaltyAccount}
          member={account.member}
        />
      </div>
      <div className="grid gap-y-[16px]">
        {expiringPointDeadlines?.length ? (
          <PointExpirationPreview
            data-testid="point-expiration-preview"
            deadlines={expiringPointDeadlines}
          />
        ) : null}
        <NavigationBar />
        <Switch>
          <Route
            exact
            path={`${authenticatedLoyaltyRoutePattern}/account`}
            component={AccountPage}
          />
          <Route
            path={`${authenticatedLoyaltyRoutePattern}/earning`}
            component={EarningPage}
          />
          <Route
            exact
            path={`${authenticatedLoyaltyRoutePattern}/rewards`}
            component={RewardsPage}
          />
          <Redirect
            from={authenticatedLoyaltyRoutePattern}
            to={`${authenticatedLoyaltyRoutePattern}/rewards`}
          />
        </Switch>
      </div>
    </>
  );
};

export default AuthenticatedLoyaltyResourcePage;
