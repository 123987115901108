import { IdentifierType } from 'routes/profile/models/Identifier';
import { Buyer } from 'rpc/model/squareup/buyerportal/buyer/data';

export type SignInIdentifierType = IdentifierType.Phone | IdentifierType.Email;

export enum OnboardingVariant {
  PrefilledPhone = 'PREFILLED_PHONE',
  Loyalty = 'LOYALTY',
}

export interface SignInIdentifier {
  id?: string;
  value: string;
  type: SignInIdentifierType;
}

export interface Name {
  firstName: string;
  lastName: string;
}

export type OnboardingVariantValue = OnboardingVariant[keyof OnboardingVariant];

export interface CollectedNativeSignInData {
  existingBuyer: boolean;
  firstIdentifier?: SignInIdentifier;
  secondIdentifier?: SignInIdentifier;
  name?: Name;
  buyer?: Buyer;
}

export type PrefilledPhoneData = {
  obfuscatedValue: string;
  fingerprint: string;
};

export type LoyaltyData = {
  merchantId: string;
  programId: string;
  isTosConsentOptional?: boolean;
  claimPointsToken?: string;
  buyer?: Buyer;
};

export type SignInVariantData = {
  [OnboardingVariant.PrefilledPhone]: PrefilledPhoneData;
  [OnboardingVariant.Loyalty]: LoyaltyData;
};

export type SignInVariantDataPayload =
  SignInVariantData[keyof SignInVariantData];

/**
 * Given that we don't require email verification for new buyers when providing their email 2nd,
 * this codifies the two distinct actions that can be taken after collecting the identifier value
 * on the AddIdentifierStep
 */
export enum AddIdentifierNextAction {
  CreateProfile,
  CollectTOTP,
}
