import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ToastVariant,
  closeToast,
  selectActiveToasts,
  updateHasOpened,
} from 'store/toastSlice';
import { MarketToaster, MarketToast } from '@market/react';

const PERSISTENT_TOAST_VARIANTS: Set<ToastVariant> = new Set([
  ToastVariant.Warning,
  ToastVariant.Critical,
]);

const GlobalToast: React.FC = () => {
  const dispatch = useDispatch();
  const activeToasts = useSelector(selectActiveToasts);
  const toasterElRef = useRef<HTMLMarketToasterElement>(null);
  const toastRefs = useRef<Record<string, HTMLMarketToastElement>>({});

  useEffect(() => {
    Object.keys(activeToasts).forEach((toastId) => {
      const toast = activeToasts[toastId];
      if (
        !toast.hasOpened &&
        toastRefs.current[toastId] &&
        toasterElRef.current
      ) {
        toasterElRef.current.show(toastRefs.current[toastId]);
        dispatch(updateHasOpened(toastId));
      }
    });
  }, [dispatch, activeToasts]);

  const handleDismiss = (toastId: string) => dispatch(closeToast(toastId));

  const isPersistent = (toastId: string) => {
    const toast = activeToasts[toastId];
    if (toast.persistent) {
      return true;
    }

    if (toast.variant) {
      return PERSISTENT_TOAST_VARIANTS.has(activeToasts[toastId].variant!);
    }

    return false;
  };

  return (
    <>
      <MarketToaster ref={toasterElRef} />
      {Object.entries(activeToasts).map(([toastId, toastData]) => (
        <div key={toastId} style={{ display: 'none' }}>
          <MarketToast
            data-testid={`sq-toast-${toastId}`}
            ref={(domNode) => {
              if (domNode === null) {
                return;
              }
              toastRefs.current[toastId] = domNode;
            }}
            variant={toastData.variant || 'info'}
            onMarketToastAutoDismissed={() => handleDismiss(toastId)}
            onMarketToastManuallyDismissed={() => handleDismiss(toastId)}
            {...(isPersistent(toastId) && { persistent: true })}
          >
            {toastData.message}
          </MarketToast>
        </div>
      ))}
    </>
  );
};

export default GlobalToast;
