import { MarketAccessory, MarketRow } from '@market/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import Call from 'svgs/market-icons/Call';
import { selectLoyaltyPhoneIdentifier } from '../loyaltySlice';

const LinkedPhoneNumber = () => {
  const { t } = useTranslation();
  const loyaltyPhoneIdentifier = useSelector((state: AppState) =>
    selectLoyaltyPhoneIdentifier(state)
  );

  return (
    <>
      <section>
        <MarketRow>
          <label slot="label">{t('loyalty.linkedPhoneNumber.label')}</label>
          <label slot="subtext">{t('loyalty.linkedPhoneNumber.subtext')}</label>
        </MarketRow>
      </section>
      <section>
        <MarketRow>
          <MarketAccessory slot="leading-accessory">
            <Call />
          </MarketAccessory>
          <label slot="label">
            {loyaltyPhoneIdentifier?.displayValue ?? t('common.unknown')}
          </label>
        </MarketRow>
      </section>
    </>
  );
};

export default LinkedPhoneNumber;
