import React, { useContext } from 'react';
import LinkedPhoneNumber from '../components/LinkedPhoneNumber';
import SignIntoSquareProfileSection from '../components/SignIntoSquareProfileSection';
import { MarketDivider } from '@market/react';
import EnrollmentDetails from '../components/EnrollmentDetails';
import LinkedPaymentCards, {
  LinkedPaymentCardsUnauthorized,
} from '../components/LinkedPaymentCards';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import {
  selectLinkedPaymentCards,
  selectLoyaltyPhoneIdentifier,
} from '../loyaltySlice';
import { useSearchLinkedPaymentMethodsQuery } from 'store/query/api-extensions/loyalty';

const AccountPage = () => {
  const mpContext = useContext(MerchantPortalContext);
  const isBuyerLoggedIn = Boolean(mpContext.merchantPortalBaseData?.buyer);
  const loyaltyPhoneIdentifier = useSelector((state: AppState) =>
    selectLoyaltyPhoneIdentifier(state)
  );
  const paymentCards = useSelector((state: AppState) =>
    selectLinkedPaymentCards(state.loyaltyMerchantPortal)
  );
  useSearchLinkedPaymentMethodsQuery(loyaltyPhoneIdentifier!, {
    skip: !isBuyerLoggedIn,
  });

  return (
    <div>
      <LinkedPhoneNumber />
      {!isBuyerLoggedIn && (
        <>
          <MarketDivider />
          <LinkedPaymentCardsUnauthorized />
        </>
      )}
      {isBuyerLoggedIn && paymentCards.length > 0 && (
        <>
          <MarketDivider />
          <LinkedPaymentCards paymentCards={paymentCards} />
        </>
      )}
      <br></br>
      <EnrollmentDetails />
      <MarketDivider />
      <SignIntoSquareProfileSection />
    </div>
  );
};

export default AccountPage;
