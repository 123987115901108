import React from 'react';
import BaseModal from 'components/modals/BaseModal';
import { MarketHeader, MarketList, MarketRow } from '@market/react';
import { ExpiringPointsDeadline } from '../models/ExpiringPointsDeadline';
import { DateFormat, dayjs, formatDate } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { LoyaltyProgramExpirationPolicyFactory } from 'routes/merchant-scoped-portal/integrations/loyalty/testing/factories/LoyaltyProgram';

export interface PointsExpirationModalProps {
  deadlines: Array<ExpiringPointsDeadline>;
}

const EXPIRATION_POLICY = LoyaltyProgramExpirationPolicyFactory.build();

const PointsExpirationModal: React.FC<PointsExpirationModalProps> = (props) => {
  const { t } = useTranslation();
  const expirationDuration = dayjs.duration(
    EXPIRATION_POLICY.expirationDuration
  );
  const expirationDurationInMonths = expirationDuration.months();
  const description =
    expirationDurationInMonths === 1
      ? t('loyalty.pointsExpirationModal.expirationPolicy.one', {
          count: expirationDurationInMonths,
        })
      : t('loyalty.pointsExpirationModal.expirationPolicy.other', {
          count: expirationDurationInMonths,
        });

  return (
    <BaseModal>
      <MarketHeader>
        <h2>{t('loyalty.pointsExpirationModal.title')}</h2>
      </MarketHeader>
      <p>{description}</p>
      <MarketList>
        {props.deadlines?.map((deadline) => (
          <MarketRow
            data-testid="points-expiration-modal-row"
            key={deadline.expiresAt?.toISOString()}
          >
            <label slot="label">
              {formatDate(deadline.expiresAt!, DateFormat.ll)}
            </label>
            <label slot="side-label">{deadline.points}</label>
          </MarketRow>
        ))}
      </MarketList>
    </BaseModal>
  );
};

export default PointsExpirationModal;
