import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdentifierType } from 'routes/profile/models/Identifier';
import { CardInfo } from 'rpc/model/squareup/buyerportal/common/data';
import {
  AccountInfo,
  ProgramInfo,
} from 'rpc/model/squareup/buyerportal/merchantportal/loyalty/data';
import { EmailCollectionIncentiveTermsAndStatus } from 'rpc/model/squareup/card/balance/loyalty_api/model/email-collection-incentive-terms-and-status';
import { LoyaltyProgram } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { LoyaltyPromotion } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-promotion';
import { LoyaltyTermsOfService } from 'rpc/model/squareup/card/balance/model/loyalty-terms-of-service';
import { MembershipProgram } from 'rpc/model/squareup/memberships/model/membership-program';
import { CreatePersonalizedLoyaltyPassUrlResponse } from 'rpc/model/squareup/passes/service/url-service';
import { AppState } from 'store';
import { selectIdentifierCollection } from 'store/buyerSlice';

export type LoyaltyMerchantPortalState = {
  accountInfo?: AccountInfo;
  availableNewTos?: LoyaltyTermsOfService;
  emailCollectionIncentiveTermsAndStatus?: EmailCollectionIncentiveTermsAndStatus;
  linkedPaymentCards: Array<CardInfo>;
  loyaltyAccountLookupToken?: string;
  passDownloadData?: CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData;
  programInfo?: ProgramInfo;
  promotions?: LoyaltyPromotion[];
};

// Selector insulates consumers from the changes to the internal state shape
// (and adds opportunities for memoization)
export const selectAssociatedPhone = (
  state: LoyaltyMerchantPortalState
): string | undefined => {
  if (!state.loyaltyAccountLookupToken) {
    return undefined;
  }
  return state.loyaltyAccountLookupToken;
};

export const selectAccount = (
  state: LoyaltyMerchantPortalState
): AccountInfo | undefined => {
  return state.accountInfo;
};

export const selectEmailCollectionIncentiveTermsAndStatus = (
  state: LoyaltyMerchantPortalState
) => {
  return state.emailCollectionIncentiveTermsAndStatus;
};

// Returns the phone identifier associated with the loyalty account, if any
// Preferentially returns a verified identifier
export const selectLoyaltyPhoneIdentifier = createSelector(
  (state: AppState) =>
    state.loyaltyMerchantPortal?.accountInfo?.phoneIdentifier,
  (state: AppState) => selectIdentifierCollection(state, IdentifierType.Phone),
  (loyaltyPhoneIdentifier, verifiedPhoneIdentifiers = []) => {
    if (!loyaltyPhoneIdentifier) {
      return null;
    }

    const verifiedLoyaltyPhoneIdentifier = verifiedPhoneIdentifiers.find(
      (identifier) => identifier.token === loyaltyPhoneIdentifier?.id
    );

    return verifiedLoyaltyPhoneIdentifier?.toProto() ?? loyaltyPhoneIdentifier;
  }
);

export const selectPassDownloadUrl = (state: LoyaltyMerchantPortalState) => {
  const passDownloadData = state.passDownloadData;

  if (!passDownloadData) {
    return null;
  }

  if (passDownloadData.isInstalled) {
    return null;
  }

  return passDownloadData.passDownloadUrl;
};

export const selectLinkedPaymentCards = (
  state: LoyaltyMerchantPortalState
): Array<CardInfo> => {
  return state.linkedPaymentCards;
};

export const selectLoyaltyProgram = (
  state: LoyaltyMerchantPortalState
): LoyaltyProgram | undefined => {
  return state.programInfo?.loyaltyProgram;
};

export const selectMembershipProgram = (
  state: LoyaltyMerchantPortalState
): MembershipProgram | undefined => {
  return state.programInfo?.membershipProgram;
};

export const selectPointsTerminology = createSelector(
  (state: AppState) => selectLoyaltyProgram(state.loyaltyMerchantPortal),
  (loyaltyProgram) => loyaltyProgram?.terminology
);

export const selectPromotions = (
  state: LoyaltyMerchantPortalState
): LoyaltyPromotion[] | undefined => {
  return state.promotions;
};

export const selectAvailableNewTos = (
  state: LoyaltyMerchantPortalState
): LoyaltyTermsOfService | undefined => {
  return state.availableNewTos;
};

export const initialState: LoyaltyMerchantPortalState = {
  accountInfo: undefined,
  availableNewTos: undefined,
  linkedPaymentCards: [],
  loyaltyAccountLookupToken: undefined,
  passDownloadData: undefined,
  programInfo: undefined,
  promotions: [],
};

const loyaltyMerchantPortalSlice = createSlice({
  initialState,
  name: 'loyaltyMerchantPortal',
  reducers: {
    collectEmailIncentive(state) {
      const { emailCollectionIncentiveTermsAndStatus } = state;
      if (!emailCollectionIncentiveTermsAndStatus) {
        return;
      }

      emailCollectionIncentiveTermsAndStatus.eligible = false;
      Object.assign(state, {
        emailCollectionIncentiveTermsAndStatus,
      });
    },
    removePaymentCard(state, action: PayloadAction<string>) {
      const cardId = action.payload;
      const linkedPaymentCards = state.linkedPaymentCards.filter(
        (card) => card.id !== cardId
      );
      Object.assign(state, { linkedPaymentCards });
    },
    setAccountInfo(state, action: PayloadAction<AccountInfo>) {
      Object.assign(state, { accountInfo: action.payload });
    },
    setAvailableNewTos(
      state,
      action: PayloadAction<LoyaltyTermsOfService | undefined>
    ) {
      Object.assign(state, { availableNewTos: action.payload });
    },
    setEmailCollectionIncentiveTermsAndStatus(
      state,
      action: PayloadAction<EmailCollectionIncentiveTermsAndStatus | undefined>
    ) {
      Object.assign(state, {
        emailCollectionIncentiveTermsAndStatus: action.payload,
      });
    },
    setLinkedPaymentCards(state, action: PayloadAction<Array<CardInfo>>) {
      Object.assign(state, {
        linkedPaymentCards: action.payload,
      });
    },
    setPassDownloadData(
      state,
      action: PayloadAction<
        | CreatePersonalizedLoyaltyPassUrlResponse.PersonalizedPassDownloadData
        | undefined
      >
    ) {
      Object.assign(state, { passDownloadData: action.payload });
    },
    setProgramInfo(state, action: PayloadAction<ProgramInfo>) {
      Object.assign(state, { programInfo: action.payload });
    },
    setPromotions(state, action: PayloadAction<LoyaltyPromotion[]>) {
      Object.assign(state, { promotions: action.payload });
    },
  },
});

export const {
  collectEmailIncentive,
  removePaymentCard,
  setAccountInfo,
  setAvailableNewTos,
  setEmailCollectionIncentiveTermsAndStatus,
  setLinkedPaymentCards,
  setPassDownloadData,
  setProgramInfo,
  setPromotions,
} = loyaltyMerchantPortalSlice.actions;

export default loyaltyMerchantPortalSlice.reducer;
