import React from 'react';
import { LoyaltyAccount } from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-account';
import { Member } from 'rpc/model/squareup/memberships/model/member';
import { useTranslation } from 'react-i18next';
import { MemberStatus } from 'routes/merchant-scoped-portal/integrations/loyalty/models/MemberStatus';
import { MarketDivider, MarketLink } from '@market/react';
import { LoyaltyProgramTerminologyFactory } from 'routes/merchant-scoped-portal/integrations/loyalty/testing/factories/LoyaltyProgram';
import { useDispatch, useSelector } from 'react-redux';
import { ModalType, openModal } from 'store/modalSlice';
import { AppState } from 'store';
import {
  selectLoyaltyProgram,
  selectMembershipProgram,
} from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';

interface LoyaltyAccountStatusProps {
  loyaltyAccount: LoyaltyAccount;
  member?: Member;
}

const TERMINOLOGY = LoyaltyProgramTerminologyFactory.build();

const LoyaltyAccountStatus: React.FC<LoyaltyAccountStatusProps> = ({
  loyaltyAccount,
  member,
}) => {
  const { t } = useTranslation();

  const memberStatus = member ? MemberStatus.fromMemberProto(member) : null;
  const tierName = memberStatus?.currentTierName;

  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );
  const membershipProgram = useSelector((state: AppState) =>
    selectMembershipProgram(state.loyaltyMerchantPortal)
  );

  const dispatch = useDispatch();
  const openTierProgramDetailsModal = () => {
    dispatch(
      openModal({
        props: {
          loyaltyProgram,
          memberStatus,
          membershipProgram,
        },
        type: ModalType.LoyaltyTierProgramDetailsModal,
      })
    );
  };

  return (
    <div className="flex justify-center items-center py-8 mb-4">
      <div
        data-testid="loyalty__account-status__card"
        className="shadow-2xl rounded-xl p-6 w-full max-w-xs bg-white"
      >
        <p className="m-0 font-medium text-gray-500 text-center">
          {t('loyalty.accountStatus.balance.label')}
        </p>
        <h1 className="mt-1 text-center">
          {t('loyalty.common.points', {
            count: loyaltyAccount.balance,
            pointsTerminology:
              loyaltyAccount.balance === 1
                ? TERMINOLOGY.one
                : TERMINOLOGY.other,
          })}
        </h1>
        {memberStatus && (
          <>
            <MarketDivider size="thin" />
            <div className="flex justify-between">
              <label className="font-medium">
                {t('loyalty.accountStatus.tierName', { tierName })}
              </label>
              <MarketLink onClick={openTierProgramDetailsModal}>
                {t('loyalty.accountStatus.viewBenefits')}
              </MarketLink>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoyaltyAccountStatus;
