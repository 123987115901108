import { MarketContentCard, MarketLink } from '@market/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface SignInCtaProps {
  displayText: string;
}

const SignInCta: React.FC<SignInCtaProps> = ({ displayText }) => {
  const history = useHistory();

  return (
    <MarketContentCard className="flex justify-center">
      <MarketLink
        data-testid="sign-in-link"
        onClick={() => history.push('/signin')}
      >
        {displayText}
      </MarketLink>
    </MarketContentCard>
  );
};

export default SignInCta;
