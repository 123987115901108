import { Factory } from 'rosie';
import {
  LoyaltyProgram,
  LoyaltyProgramAccrualRule,
  LoyaltyProgramExpirationPolicy,
  LoyaltyProgramRewardTier,
  LoyaltyProgramStatus,
  LoyaltyProgramTerminology,
} from 'rpc/model/squareup/card/balance/loyalty_api/model/loyalty-program';
import { Money, Currency } from 'rpc/model/squareup/connect/v2/common/money';

export const LoyaltyProgramExpirationPolicyFactory =
  new Factory<LoyaltyProgramExpirationPolicy>().attr(
    'expirationDuration',
    () => 'P12M'
  );

export const LoyaltyProgramRewardTierFactory =
  new Factory<LoyaltyProgramRewardTier>()
    .sequence('id', (i) => `reward-tier-${i}`)
    .sequence('name', (i) => `Reward ${i}`)
    .sequence('points', (i) => i * 200);

export const LoyaltyProgramTerminologyFactory =
  new Factory<LoyaltyProgramTerminology>()
    .attr('one', 'Point')
    .attr('other', 'Points');

export const LoyaltyProgramAccrualRuleFactory =
  new Factory<LoyaltyProgramAccrualRule>()
    .option('excludedCategoryIds', [])
    .option('excludedItemVariationIds', [])
    .option('itemVariationId', 'item-variation-id')
    .option('categoryId', 'category-id')
    .attr('accrualType', LoyaltyProgramAccrualRule.Type.VISIT)
    .attr('points', 10)
    .attr(
      'visitData',
      LoyaltyProgramAccrualRule.VisitData.create({
        minimumAmountMoney: Money.create({
          amount: 1000,
          currency: Currency.USD,
        }),
      })
    )
    .attr(
      'spendData',
      ['excludedCategoryIds', 'excludedItemVariationIds'],
      (excludedCategoryIds: string[], excludedItemVariationIds: string[]) =>
        LoyaltyProgramAccrualRule.SpendData.create({
          amountMoney: Money.create({ amount: 1000, currency: Currency.USD }),
          excludedCategoryIds,
          excludedItemVariationIds,
        })
    )
    .attr('itemVariationData', ['itemVariationId'], (itemVariationId: string) =>
      LoyaltyProgramAccrualRule.ItemVariationData.create({ itemVariationId })
    )
    .attr('categoryData', ['categoryId'], (categoryId: string) =>
      LoyaltyProgramAccrualRule.CategoryData.create({ categoryId })
    )
    .after((loyaltyProgramAccrualRule) => {
      const builder = new LoyaltyProgramAccrualRule.Builder()
        .accrualType(loyaltyProgramAccrualRule.accrualType)
        .points(loyaltyProgramAccrualRule.points);

      switch (loyaltyProgramAccrualRule.accrualType) {
        case LoyaltyProgramAccrualRule.Type.VISIT: {
          builder.visitData(loyaltyProgramAccrualRule.visitData);
          break;
        }
        case LoyaltyProgramAccrualRule.Type.SPEND: {
          builder.spendData(loyaltyProgramAccrualRule.spendData);
          break;
        }
        case LoyaltyProgramAccrualRule.Type.ITEM_VARIATION: {
          builder.itemVariationData(
            loyaltyProgramAccrualRule.itemVariationData
          );
          break;
        }
        case LoyaltyProgramAccrualRule.Type.CATEGORY: {
          builder.categoryData(loyaltyProgramAccrualRule.categoryData);
          break;
        }
        default: {
          break;
        }
      }

      return builder.build();
    });

export const LoyaltyProgramFactory = new Factory<LoyaltyProgram>()
  .attr('id', 'loyalty-program-id')
  .attr('status', LoyaltyProgramStatus.ACTIVE)
  .attr('rewardTiers', LoyaltyProgramRewardTierFactory.buildList(3))
  .attr('accrualRules', [LoyaltyProgramAccrualRuleFactory.build()])
  .attr('expirationPolicy', LoyaltyProgramExpirationPolicyFactory.build())
  .attr('terminology', LoyaltyProgramTerminologyFactory.build())
  .attr('locationIds', ['location-id'])
  .attr('isAppleVasIntegrationEnabled', true)
  .attr('isCashIntegrationEnabled', true)
  .attr('createdAt', new Date().toISOString())
  .attr('updatedAt', new Date().toISOString())
  .attr('version', 1);
