import React, { useCallback, useEffect } from 'react';
import { MarketButton, MarketDivider } from '@market/react';
import { useTranslation } from 'react-i18next';
import LoyaltyProgramOverview from '../components/LoyaltyProgramOverview';
import useNavigateToLoyaltyOnboarding from 'utils/custom-react-hooks/loyalty/useNavigateToLoyaltyOnboarding';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import {
  ActionItem,
  EventName,
  FeatureFormat,
} from 'services/tracking/cdp/events/types';

const UnauthenticatedLoyaltyResourcePage = () => {
  const { t } = useTranslation();

  const navigateToLoyaltyOnboarding = useNavigateToLoyaltyOnboarding();
  const trackLoyaltyEvent = useTrackLoyaltyEvent();

  const enroll = useCallback(() => {
    trackLoyaltyEvent(EventName.CLICK_FEATURE, {
      action_item: ActionItem.CLICK_BUTTON,
      event_description: 'Click sign up or check in button',
      feature_format: FeatureFormat.BUTTON,
    });
    navigateToLoyaltyOnboarding();
  }, [navigateToLoyaltyOnboarding, trackLoyaltyEvent]);

  useEffect(() => {
    trackLoyaltyEvent(EventName.VIEW_FEATURE, {
      event_description: 'View default sign up/log in page',
      feature_format: FeatureFormat.PAGE,
    });
  }, [trackLoyaltyEvent]);

  return (
    <div
      className="grid"
      data-testid="loyalty__unauthenticated-resource-page__container"
    >
      <p className="text-center mt-[-28px] mb-8">
        {t('loyalty.program.genericDescription')}
      </p>
      <MarketButton
        onClick={enroll}
        rank="primary"
        data-testid="loyalty__unauthenticated-resource-page__enroll-button"
      >
        {t('loyalty.program.enroll.button.label')}
      </MarketButton>
      <MarketDivider margin="large" />
      <LoyaltyProgramOverview />
    </div>
  );
};

export default UnauthenticatedLoyaltyResourcePage;
