import {
  MarketAccessory,
  MarketButton,
  MarketEmptyState,
  MarketLink,
  MarketList,
  MarketRow,
} from '@market/react';
import CardIcon from 'components/profile/BrandedCardIcon';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ICardInfo } from 'rpc/model/squareup/buyerportal/common/data';
import { AppState } from 'store';
import { useUnlinkPaymentCardMutation } from 'store/query/api-extensions/loyalty';
import { selectLoyaltyPhoneIdentifier } from '../loyaltySlice';
import { openToast } from 'store/toastSlice';

interface PaymentCardProps {
  cardInfo: ICardInfo;
}

const HeadingSection = () => {
  const { t } = useTranslation();
  return (
    <section>
      <MarketRow>
        <label slot="label">{t('loyalty.linkedPaymentCards.label')}</label>
        <label slot="subtext">{t('loyalty.linkedPaymentCards.subtext')}</label>
      </MarketRow>
    </section>
  );
};

export const LinkedPaymentCardsUnauthorized = () => (
  <>
    <HeadingSection />
    <MarketEmptyState data-testid="linked-payment-cards__logged-out-state">
      <p slot="secondary-text">
        <Trans
          i18nKey="loyalty.linkedPaymentCards.signIn"
          components={{
            signInLink: (
              <MarketLink
                data-testid="linked-payment-cards__sign-in-link"
                href={`/signin?return_to=${encodeURIComponent(
                  window.location.href
                )}`}
              ></MarketLink>
            ),
          }}
        ></Trans>
      </p>
    </MarketEmptyState>
  </>
);

const PaymentCard: React.FC<PaymentCardProps> = ({ cardInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loyaltyPhoneIdentifer = useSelector((state: AppState) =>
    selectLoyaltyPhoneIdentifier(state)
  );
  const [unlink, unlinkResult] = useUnlinkPaymentCardMutation();
  const handleUnlink = async () => {
    try {
      await unlink({
        cardId: cardInfo.id!,
        loyaltyPhoneIdentifier: loyaltyPhoneIdentifer!,
      }).unwrap();
      dispatch(
        openToast({
          message: t('loyalty.linkedPaymentCards.unlink.toast.success'),
          variant: 'success',
        })
      );
    } catch {
      dispatch(
        openToast({
          message: t('common.somethingWentWrong.blocked'),
          variant: 'critical',
        })
      );
    }
  };

  return (
    <MarketRow data-testid="loyalty__linked-payment-card">
      <MarketAccessory size="image" slot="leading-accessory">
        <CardIcon brand={cardInfo.brand} />
      </MarketAccessory>
      <label slot="label">{cardInfo.displayName}</label>
      <MarketButton
        isLoading={unlinkResult.isLoading}
        onClick={handleUnlink}
        slot="trailing-accessory"
        variant="destructive"
      >
        {t('common.unlink')}
      </MarketButton>
    </MarketRow>
  );
};

interface LinkedPaymentCardsProps {
  paymentCards: Array<ICardInfo>; // assume a non-empty list; there is no empty state
}

const LinkedPaymentCards: React.FC<LinkedPaymentCardsProps> = ({
  paymentCards,
}) => {
  return (
    <>
      <HeadingSection />
      <MarketList>
        {paymentCards.map((card) => (
          <PaymentCard key={card.id} cardInfo={card} />
        ))}
      </MarketList>
    </>
  );
};

export default LinkedPaymentCards;
