import React, { useContext, useMemo } from 'react';
import ContentContainer from './layout-components/ContentContainer';
import AboutUs from './layout-components/AboutUs';
import { ErrorBoundary } from 'react-error-boundary';
import { MarketDivider } from '@market/react';
import { MerchantPortalContext } from '.';
import LoyaltyOverviewTile from './integrations/loyalty/overview/LoyaltyOverviewTile';
import { logUnexpectedError } from 'routes/merchant-scoped-portal/utils/error';
import { Team } from './teamRegistry';
import { useRetrieveProgramInfoQuery } from 'store/query/api-extensions/loyalty';
// ...other tiles

const MerchantOverviewPage: React.FC = () => {
  const { merchantPortalBaseData } = useContext(MerchantPortalContext);
  const { merchantOverview } = merchantPortalBaseData ?? {};
  const { businessDescription, merchantId, websiteUrl } =
    merchantOverview ?? {};
  const programInfoResult = useRetrieveProgramInfoQuery(merchantId ?? '', {
    skip: !merchantId,
  });
  const maybeLoyaltyTile = useMemo(() => {
    // Don’t show loyalty overview tile while loading; the program may not exist, and showing the a spinner
    // would result in a flicker
    if (programInfoResult.isLoading || programInfoResult.isUninitialized) {
      return null;
    }

    // The program does not exist
    if (!programInfoResult.data?.loyaltyProgram) {
      return null;
    }

    return (
      <ErrorBoundary
        key={Team.Loyalty}
        /* Since this just catches entirely unexpected errors, there's no real fallback to present. */
        fallback={null}
        onError={(error) => logUnexpectedError(error, [Team.Loyalty])}
      >
        <MarketDivider margin="large" />
        <LoyaltyOverviewTile program={programInfoResult.data?.loyaltyProgram} />
      </ErrorBoundary>
    );
  }, [
    programInfoResult.data,
    programInfoResult.isLoading,
    programInfoResult.isUninitialized,
  ]);

  return (
    <>
      {/* This has the styles to center and add padding */}
      <ContentContainer>
        <AboutUs
          businessDescription={businessDescription}
          websiteUrl={websiteUrl}
        />
        {maybeLoyaltyTile}
      </ContentContainer>
    </>
  );
};

export default MerchantOverviewPage;
