import React, { useCallback, useContext } from 'react';
import { LogoIcon } from 'svgs';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketRow } from '@market/react';
import nonTranslatableStrings from 'utils/nonTranslatableStrings';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { useTrackLoyaltyEvent } from 'utils/custom-react-hooks/loyalty/useTrackLoyaltyEvent';
import {
  ActionItem,
  EventName,
  FeatureFormat,
} from 'services/tracking/cdp/events/types';

const SignIntoSquareProfileSection = () => {
  const { t } = useTranslation();

  const mpContext = useContext(MerchantPortalContext);
  const isBuyerLoggedIn = Boolean(mpContext.merchantPortalBaseData?.buyer);

  const trackLoyaltyEvent = useTrackLoyaltyEvent();

  const buttonHref = isBuyerLoggedIn ? '/' : '/signin';
  const buttonText = isBuyerLoggedIn
    ? t('loyalty.signIntoSquareProfileSection.button.goToSquareProfile', {
        SQUARE: nonTranslatableStrings.square,
      })
    : t('loyalty.signIntoSquareProfileSection.button.signIn');

  const onButtonClick = useCallback(() => {
    trackLoyaltyEvent(EventName.CLICK_FEATURE, {
      action_item: ActionItem.CLICK_BUTTON,
      event_description: isBuyerLoggedIn
        ? 'Go to Square profile'
        : 'Sign in to Square profile',
      feature_format: FeatureFormat.BUTTON,
    });
  }, [isBuyerLoggedIn, trackLoyaltyEvent]);

  return (
    <section className="py-4">
      <LogoIcon
        data-testid="loyalty__sign-into-square-profile-section__logo"
        height={20}
        width={20}
      />
      <MarketRow>
        <label slot="label">
          {t('loyalty.signIntoSquareProfileSection.header', {
            SQUARE: nonTranslatableStrings.square,
          })}
        </label>
        <p slot="subtext">
          {t('loyalty.signIntoSquareProfileSection.description', {
            SQUARE: nonTranslatableStrings.square,
          })}
        </p>
      </MarketRow>
      <MarketButton
        data-testid="loyalty__sign-into-square-profile-section__button"
        className="w-full mt-3"
        href={buttonHref}
        onClick={onButtonClick}
      >
        {buttonText}
      </MarketButton>
    </section>
  );
};

export default SignIntoSquareProfileSection;
