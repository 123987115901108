import BaseModal from 'components/modals/BaseModal';
import React, { useCallback, useContext, useMemo } from 'react';
import {
  MarketAccessory,
  MarketDivider,
  MarketHeader,
  MarketLink,
  MarketRow,
} from '@market/react';
import { useTranslation } from 'react-i18next';
import { MerchantPortalContext } from 'routes/merchant-scoped-portal';
import { useGetMerchantLocationsQuery } from 'store/query/api-extensions/merchantPortalBaseRpcs';
import ModuleLoading from 'routes/profile/common/loading/ModuleLoading';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import LocationList from 'routes/merchant-scoped-portal/components/merchant-location/LocationList';
import { formatMerchantLocations } from 'routes/merchant-scoped-portal/components/merchant-location/view-models/FormattedMerchantLocation';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { selectLoyaltyProgram } from 'routes/merchant-scoped-portal/integrations/loyalty/loyaltySlice';
import Store from 'svgs/market-icons/Store';
import { normalizeUrl } from 'utils/url';

const RedemptionOptionsModal: React.FC = () => {
  const { t } = useTranslation();
  const { merchantPortalBaseData } = useContext(MerchantPortalContext);
  const merchantId = merchantPortalBaseData?.merchantOverview.merchantId ?? '';
  const sites = merchantPortalBaseData?.merchantOverview.sites;

  const publishedSiteUrls = useMemo(() => {
    return sites
      ? sites
          .filter((site) => site.isPublished)
          .map((site) => site.domain)
          .filter(Boolean)
      : [];
  }, [sites]);

  const {
    data: merchantLocations,
    isLoading,
    isError,
  } = useGetMerchantLocationsQuery(merchantId);

  const loyaltyProgram = useSelector((state: AppState) =>
    selectLoyaltyProgram(state.loyaltyMerchantPortal)
  );

  const loyaltyLocations =
    merchantLocations?.filter((location) =>
      loyaltyProgram?.locationIds?.includes(location.locationId)
    ) ?? [];

  const renderHeaderAndSites = useCallback(() => {
    if (publishedSiteUrls.length > 0) {
      return (
        <>
          <MarketHeader
            data-testid="loyalty__redemption-options-modal__header"
            className="mb-4"
          >
            <h2>{t('loyalty.redemptionOptionsModal.redeemOnline.header')}</h2>
          </MarketHeader>
          {publishedSiteUrls.map((siteUrl) => (
            <MarketRow key={siteUrl}>
              <MarketAccessory
                className="bg-fill-40 rounded"
                size="image"
                slot="leading-accessory"
              >
                <Store width={24} height={24} fill="black" />
              </MarketAccessory>
              <label slot="label">
                <MarketLink
                  className="text-black"
                  href={normalizeUrl(siteUrl)}
                  color="black"
                  target="_blank"
                >
                  {siteUrl}
                </MarketLink>
              </label>
            </MarketRow>
          ))}
          <MarketDivider margin="large" />
          <h2>{t('loyalty.redemptionOptionsModal.redeemInStore.header')}</h2>
        </>
      );
    } else {
      return (
        <MarketHeader
          data-testid="loyalty__redemption-options-modal__header"
          className="mb-4"
        >
          <h2>{t('loyalty.redemptionOptionsModal.redeemInStore.header')}</h2>
        </MarketHeader>
      );
    }
  }, [publishedSiteUrls, t]);

  if (isLoading) {
    return <ModuleLoading embedded />;
  }
  if (isError) {
    return <ModuleLoadFailed embedded />;
  }

  return (
    <BaseModal>
      {renderHeaderAndSites()}
      <LocationList
        merchantLocations={formatMerchantLocations(loyaltyLocations)}
        shouldShowContactInfo={true}
      />
    </BaseModal>
  );
};

export default RedemptionOptionsModal;
