import React, { createContext, useContext, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  AuthenticatedLoyaltyRouteParams,
  authenticatedLoyaltyRoutePattern,
} from './routeUtils';

export interface LoyaltyContextData {
  loyaltyAccountLookupToken?: string;
}

export const LoyaltyContext = createContext<LoyaltyContextData>({});

// Create a provider component for the LoyaltyContext that pulls the loyalty account lookup
// token from the URL path if present
export const LoyaltyContextProvider: React.FC = ({ children }) => {
  const [loyaltyToken, setLoyaltyToken] = useState<string | undefined>(
    undefined
  );

  const match = useRouteMatch<Partial<AuthenticatedLoyaltyRouteParams>>(
    authenticatedLoyaltyRoutePattern
  );

  useEffect(() => {
    if (match?.params?.loyaltyAccountLookupToken) {
      setLoyaltyToken(match.params.loyaltyAccountLookupToken);
    } else {
      setLoyaltyToken(undefined);
    }
  }, [match]);

  const loyaltyContextValue: LoyaltyContextData = {
    loyaltyAccountLookupToken: loyaltyToken,
  };

  return (
    <LoyaltyContext.Provider value={loyaltyContextValue}>
      {children}
    </LoyaltyContext.Provider>
  );
};

// Export a custom hook to consume the loyalty account lookup token
export const useLoyaltyContext = () => useContext(LoyaltyContext);
